.chat-container{
    position: absolute;
    top: 50%;
    left: 30%;
    width: 40%;
    height: fit-content;
    /* border: 1px solid black; */

}
.text-bar{
    border: 1px black solid;
    height: 3.5vh;
    border-radius: 3.5vh;
    padding: 5px;
}
.text{
    position: relative;
    background: none;
    border: none;
    outline:none;
    color: black;
    opacity: 80%;
    float: left;
    padding: 0;
    padding-left: 3%;
    width: 85%;
    font-size: 1.8vh;
    line-height: 3.5vh;
}
::placeholder{
    color: black;
    opacity: 30%;
    font-family: Lato, sans-serif;
}
.send-btn{
    color: black;
    float: right;
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    font-size: 2vh;
    border-left: none;
    cursor: pointer;
}
.arrow{
    display: block;
    position: fixed;
    top: 7%;
    left: 5%;
    text-decoration: none;
    border: none;
    background: none;
    cursor: pointer;
    /* border: 1px solid black; */
    font-size: 2vw;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
}
.response-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    /* border: 1px solid black; */
}
.line_art{
    display: block;
    width: 25vw;
    /* opacity: 70%; */
}
.message{
    font-size: 1.5vw;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}
.message-filler{
    font-size: 3vw;
}
/* Buttons */
.button-bar{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    margin-top: 5vh;
    /* left: -30%; */
    /* border: 1px solid black; */
}
.rasabutton{
    position: relative;
    /* left: 0%; */
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    background: none;

    font-family: 'Roboto', sans-serif;
    letter-spacing: 4px;
    font-size: 25px;
    line-height: 5vh;
    padding: auto;
    font-weight: 400;
    color: black;

    width: 25%;
    height: 5vh;
    /* padding: 2% 2.5vw; */
    border: 1px solid black;
    border-radius: 50px;
    z-index: 3;
}
.uninterested{
    width: fit-content;
    margin-top: 3vh;
    padding: 0px 5%;
}

/* <--------------- Wave Stuff ---------------> */
@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}
.waveWrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5%;
    top: 60%;
    margin: auto;
}
.waveWrapperInner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    bottom: -1px;
    /* background-image: linear-gradient(to top, #86377b 20%, #27273c 80%); */
}
.bgTop {
    z-index: -5;
    opacity: 0.5;
}
.bgMiddle {
    z-index: -10;
    opacity: 0.75;
}
.bgBottom {
    z-index: -15;
}
.wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    transform-origin: center bottom;
}
.waveTop {
    background-size: 50% 100px;
    background-image: url('../img/wave-top.png');
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
   -webkit-animation: move-wave 3s;
   -webkit-animation-delay: 1s;
   animation-delay: 1s;
}
.waveMiddle {
    background-size: 50% 120px;
    background-image: url('../img/wave-mid.png');
}
.waveAnimation .waveMiddle {
    animation: move_wave 10s linear infinite;
}
.waveBottom {
    background-size: 50% 100px;
    background-image: url('../img/wave-bot.png');
}
.waveAnimation .waveBottom {
    animation: move_wave 15s linear infinite;
}