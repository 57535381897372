.next-button{
    left: 45%;
    width: max-content;
    padding-left: 5%;
    padding-right: 5%;
}
.work-button{
    letter-spacing: 0.5px;
    padding-left: 7%;
    padding-right: 7%;
}
.work-button-container{
    width:70%;
}
.experienceP{
    width: 45vw;
}
.experience-image{
    right: 10%;
}
.experience{
    top: 17%;
}