.menu{
    font-family: 'Lato', sans-serif;
    position:fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 1.3%;
}
.menu .logo{
    position: relative;
    width:10%;
    color: black;
    font-weight: 400;
    font-size: 2.4rem;
    cursor: pointer;
}
.menu .right{
    position: relative;
    display: flex;
    justify-content: space-around;
    width:2.2vw;
    right:3vw;
    /* border: 1px solid black; */
    /* margin-top: 0.9%; */
}

.link{
    text-decoration: none;
    color: black;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    /* padding-bottom: 5px; */
}
/* -------- burger -------- */
.menu .burger{
    flex-basis: 0;
    flex-grow: 0;
    /* border:1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.menu .burger div{
    width: 2rem;
    height: 0.3rem;
    background-color: black;
    margin: 0.16rem;
}
/* .menu .link::after{
    content: '';
    display: block;
    width: 0px;
    height: 2px;
    background: black;
    transition: width 0.3s;
}
.menu .link:hover::after{
    width: 100%;
    transition: width ease-in-out;
} */
.page-number{
    position: fixed;
    display: block;
    width: 10vw;
    height: 15rem;
    left: 0%;
    bottom: 15rem;
    font-family: 'Montserrat', serif;
    font-weight: 700;
    font-size: 12rem;
    color: gray; 
}
.vl {
    /* opacity: 0.8; */
    border-left: 1px solid gray;
    position: fixed;
}
/* .left-line {
    margin-top: 12rem;
    left : 5vw;
    height: 80rem;
} */
.left-container{
    position: fixed;
    width: 3vw;
    height: 55vh;
    left: 3%;
    top: 25%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    color: black;
    /* border: 1px solid black; */
}
.section{
    font-size: 2rem;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    transform: rotate(-90deg);
    opacity: 0.5;

    display: block;
    width: 150%;
    height: 15%;
    padding: none;
    border: none;
    background: none;
    cursor: pointer;
    /* border: 1px solid black; */
}
.selected{
    /* border: 1px black solid; */
    font-size: 2.2rem;
    opacity: 1;
}
.right-container{
    position: fixed;
    width: 2vw;
    right : 3vw;
    margin-top: 13rem;
    height: 80rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    /* border: 1px solid black;  */
}
.right-line-top{
    position: absolute;
    height: 35%;
}
.right-line-bottom{
    position: absolute;
    height: 35%;
    top: 60%;
}
.progress-bar{
    position: absolute;
    /* right : 4vw; */
    top : 35%;
    width : 2vw;
    height : 25%;
    display:flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid black; */
}
.pair{
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.square{
    height: 1.25rem;
    width: 1.25rem;
    /* border: 1px solid white; */
    transform: rotate(45deg);
    /* margin: -2px,0px,0px,-10px; */
    /* position:absolute; */
    /* right: 4.5vw; */
    background-color: none;
    border: 2px solid black;
    z-index: 10;
}
.hidden{
    display:none;
}
.circle{
    position: absolute;
    height: 0.58rem;
    width: 0.58rem;
    border: 1px solid black;
    background-color: black;
    /* background-color: rgb(71, 71, 71); */
    opacity: 0.5;
    border-radius: 50%;
    /* background-color: white; */
}