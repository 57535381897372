@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;900&family=Playfair+Display+SC:wght@400;700&family=Poppins:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@300;400&display=swap');

html::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
html{
    font-size: 1vh;
}

.App {
  text-align: center;
  height : 100rem;
}
/* -----------------------------Page 1-----------------------------*/
.container1{
    /* background: url("/static/img/container1_background.png") no-repeat; */
    background-size: cover;
    height: 100rem;
    width: 100%;
}

.background-img{
    width: 65%;
    height: 120rem;
    position: fixed;
    top: -20rem;
    right: 0%;
    /* object-fit: cover; */
    background-image: url("../img/landing_img_new.png");
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: 100vw 100vh; */

    opacity: 0;
    z-index: -1;
}
/* .landingImg{
    position: fixed;
    right:0%;
    width: 100%;
    height: 100rem;
    z-index: -1;
} */
.flex_container{
    position :absolute;
    top:30%;
    left:15%;
    display: flex;
    align-items: left;
    flex-direction: column;
    /* border: solid 3px #dfdfdf; */
    /* margin-left: 20vw; */
    color:black;
    /* color: white; */
    opacity:70%;
    width: 75vw;
    height:max-content;
    letter-spacing: 1px;
}

.flex_container h1{
    font-family: 'Playfair Display SC', serif;
    font-weight: 700;
    font-size: 7vw;
    /* padding: 30px 0px; */
    position: relative;
    left:0%;
    /* border: 1px solid black; */
    width:min-content;
    line-height: 7vw;
    /* text-shadow: 1px 1px 4px white; */
    opacity: 1;
    text-align: left;
    margin: 0px;
}
.flex-p{
    letter-spacing: 3px;
    line-height: 2rem;
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 2rem;
}
.p-block{
    font-weight: 300;
    font-size: 2rem;
    letter-spacing: 3px;
    text-align: left;
    line-height: 2;
}

.nameContainer{
    /* border: 1px black solid; */
    width: 55%;
    /* margin-bottom: -3rem;*/ 
} 
.name{
    font-family: 'Playfair Display SC', serif;
    /* font-family: 'Poppins', sans-serif; */
    /* font-family: 'Open Sans', sans-serif; */
    font-weight: 800;
    font-size: 5vw;
    text-align: left;
    height: fit-content;
}
.chatContainer{
    width: 48%;
    display: flex;
    justify-content: space-around;
    /* border: 1px solid black; */
    margin-bottom: 5%;
}
.chatName{
    font-family: 'DM Serif Display', serif;
    font-weight: 400;
    padding: 0;
    margin: 0;
    /* margin-top: -3%; */
}
.bottom-span{
    display: flex;
    width: 75%;
    /* margin-top: -3%; */
    justify-content: right;
}

.chat{
    color:black;
    opacity: 1;
    width: 40vw;
    height: fit-content;
    /* border: 1px solid black; */
    top:30%
}
.idaText{
    /* border: 1px solid black; */
    font-family: 'Open Sans', sans-serif;
    width: 33vw;
    /* border: 1px solid black; */
}
.right-img{
    position: fixed;
    top: 0%;
    right: 0%;
    z-index: -2;
    width: 45vw;
    height: 100rem;
    opacity: 0; 
    background-repeat: no-repeat;
    background-size: cover;
    /* to be animated by framer motion */
}
.top-img{
    position: fixed;
    top: 32rem;
    right: 15vw;
    z-index: -1;
    /* tricks the browser to display equal size */
    width: 25%;
    height: 0;
    padding-bottom: 25%;

    opacity: 0; 
    background-image: url("../img/chatbot_cute_one_way.jpg");
    background-repeat: no-repeat;
    background-size: contain;
}
.chat-credit{
    opacity: 0;
    position: fixed;
    right: 15vw;
    top: 76vh;
}
/* .lines{
    width:20%;
    height: 1000px;
    border: 1px solid black;
    height: fit-content;

    display: flex;
    justify-content: space-around; 
    flex-direction: column;
}
.underline{
    display:block;
    width:50px;
    border-top: 5px solid orange;
} */
.button-container{
    position: relative;
    left: 0%;
    width:50%;
    height:10%;
    margin: 5% 0px;
    /* border: 1px solid black; */
}
.button{
    position: absolute;
    left: 0%;
    display: inline-block;
    cursor: pointer;

    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2.5px;
    font-size: 2rem;
    font-weight: 400;
    color: black;

    padding: 2.3% 2.5vw;
    border: 1px solid black;
    border-radius: 5px;

    opacity: 0.85;
    background-position:left bottom;
    background: linear-gradient(to right, white 50%, rgb(31, 30, 30) 50%);
    background-size: 200% 100%;
    transition:all 0.5s ease-in-out;
}
.button:hover {
    color: white;
    background-position:right bottom;
}
.landing-button{
    padding: 1.5% 2.5vw;
    border: 2px solid black;
    /* border-radius: 50px; */
    opacity: 1;
    border-radius: 5px;
    font-size: 2rem;
    font-weight: 400;
}
/* 
@keyframes text{
    0%{
        color: black;
        margin-bottom: -40px;
    }
    30%{
        letter-spacing: 5px;
    }
} */
