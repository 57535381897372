@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
.about{
    color:black;
    opacity: 1;
    width: 40vw;
    top: 18vh;
    height: fit-content;
}
.aboutP{
    /* border: 1px solid black; */
    font-family: 'Open Sans', sans-serif;
    width: 30vw;
    /* border: 1px solid black; */
}
.aboutContainer{

}
.aboutName{
    margin-bottom: 8%;
    font-family: 'DM Serif Display', serif;
    font-weight: 400;
}
.about-image{
    position: fixed;
    border-radius: 50%;
    border: 1px solid gray;
    top: 30%;
    right: 15%;
    /* z-index: -2; */
    opacity: 0;
    width: 22%;
    height: 0;
    padding-bottom: 22%;
    background-image: url("../img/about_img.jpg");
    background-repeat: no-repeat;
    background-position: center;
    /* background-size: contain; */
    background-size: 100%;
}
.about-button{
    letter-spacing: 1px;
    padding-left: 8%;
    padding-right: 7%;
}
.about-container{
    width: 60%;
}
.next-button-about{
    left: 50%;
    letter-spacing: 1.5px;
}
