.contact{
    width: 100vw;
    height: 100vh;
    font-family: "Open Sans", sans-serif;
}
.divider{
    width: 84%;
    position: absolute;
    left: 8%;
}
.top-line{
    top: 11%;
}
.bottom-line{
    bottom:8%;
}
.contact-header{
    position: absolute;
    left: 8%;
    top: 11%;

    /* border: 1px solid black; */
    width: 10%;
}
.contact-title{
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 4.5vh;
    text-align: left;
    line-height: 5vh;
}

.contact-form{
    position: absolute;
    right: 10%;
    top: 15%;
    width: 60%;
    height: fit-content;
    /* border: 1px black solid; */

    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    flex-wrap: wrap;
}
.form-field{
    position: relative;
    width: 40%;
    height: 20%;
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
    /* border: 1px solid black; */
}

.form-field-label{
    width: 100%;
    /* border: 1px solid black; */
    font-size: 1.5vh;
    text-align: left;
    margin-bottom: 1vh;
}
.form-field-input{
    display: block;
    height: 3.5vh;
    font-size: 1.3vh;
    border: none;
    border-bottom: 1px solid black;
    padding-left: 2.5%;
    /* border-radius: 3.5vh; */
}
input.form-field-input:focus {
    outline: none;
    border-bottom: 2px solid black;
}
.form-message {
    height: 7vh;
    width: 40vw;
    border-bottom: none;
    border: 1px solid black;
    letter-spacing: 1px;
    line-height: 1.5vh;
    padding: 15px 20px;
}
textarea::-webkit-scrollbar {
    width: 0.3vw;
}

textarea::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    -webkit-box-shadow: none;
}

textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 0.15vw;
}
.required::after{
    content: "*";
    color: red;
}
.captcha-field{
    /* border: 1px solid black; */
    width: 90%;
    margin-top: 2vh;
    display: flex;
    /* justify-content: left; */
}
.captcha{
    transform: scale(0.8);
    margin-left: -11%;
}
.submit-button-container{
    width: 90%;
    /* border: 1px solid black; */
    height: fit-content;
}
.submit-button{
    background: none;
    border: none;
    float: left;
    text-align: left;
    width: 10%;
    height: 5vh;
    line-height: 5vh;
    font-size: 1.8vh;
    font-weight: 300;
    font-weight: normal;
    letter-spacing: 2px;
    cursor: pointer;
}
.schedule-field{
    width: 90%;
    height: min-content;
    cursor: pointer;
}
.schedule-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 3vh;
    border: 1px solid black;
    padding: 10px;
}
.schedule-button{
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 3px;
    font-weight: 400;

    width: 70%;
    color: black;
    padding: 10px 0px;
    /* border: 1px solid black; */
    /* border-radius: 30px; */
    font-size: 2.1rem;
}
.google-meet{
    width: 4vh;
    height: 4vh;
    padding-right: 5%;
    /* border: 4px solid black; */
}
