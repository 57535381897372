.blur{
    top: 0%;
    left: 0%;
    position: fixed;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    height: 100vh;
    width: 100vw;
    z-index: 10;
}
.meetings-page{
    position: fixed;
    top: 10vh;
    left: 30vw;
    height: 75vh;
    width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    z-index: 11;
    /* border: 1px solid black; */
}
.meeting-title{
    width: 100%;
    /* border: 1px solid black; */
    text-align: center;
    font-size: 3rem;
    font-weight: 300;
}
.meeting-row{
    font-family: "Open Sans", sans-serif;
    display: flex;
    justify-content: space-around;
    width: 70%;
    margin-top: -5%;
    /* border: 1px solid black; */
    height: fit-content;
}
.meeting-div{
    background: none;
    position: relative;
    width: 40%;
    height: 20%;
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
}
.medium-length{
    width: 60%;
}
.medium-high-length{
    width: 75%;
}
.full-length{
    width: 100%;
}
.meeting-field {
    background: none;
}
.meeting-message{
    display: block;
    height: 7vh;
    width: 100%;
    /* border-bottom: none; */
    background: none;
    border: 1px solid black;
    font-size: 1.3vh;
    font-family: inherit;
    letter-spacing: 1px;
    line-height: 1.5vh;
    padding-left: 2.5%;
    padding-top: 2.5%;
}
.date-picker-div{
    width: 70%;
    margin-top: -5%;
}
.date-picker, .time-picker{
    width: 25%;
    transform: scale(1.5);
}
.captcha-div{
    margin-left: 10%;
    /* margin-top: 3%; */
    transform: scale(0.8);
}
.button-row{
    width: 50%;
    display: flex;
    justify-content: space-around;
    margin-top: -5%;
}
.contact-button {
    background: none;
    border: 1px solid black;
    text-align: center;
    width: 40%;
    padding: 5px 8%;
    height: 5vh;
    font-size: 1.8vh;
    font-weight: 300;
    letter-spacing: 2px;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
}
.contact-button:hover{
    color: white;
    background-color: rgb(83, 83, 83);
    opacity: 0.9;
}