@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;900&family=Playfair+Display+SC:wght@400;700&family=Poppins:wght@300;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
html{
    font-size: 1vh;
}

.App {
  text-align: center;
  height : 100rem;
}
/* -----------------------------Page 1-----------------------------*/
.container1{
    /* background: url("/static/img/container1_background.png") no-repeat; */
    background-size: cover;
    height: 100rem;
    width: 100%;
}

.background-img{
    width: 65%;
    height: 120rem;
    position: fixed;
    top: -20rem;
    right: 0%;
    /* object-fit: cover; */
    background-image: url(/static/media/landing_img_new.5a2d6aaa.png);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: 100vw 100vh; */

    opacity: 0;
    z-index: -1;
}
/* .landingImg{
    position: fixed;
    right:0%;
    width: 100%;
    height: 100rem;
    z-index: -1;
} */
.flex_container{
    position :absolute;
    top:30%;
    left:15%;
    display: flex;
    align-items: left;
    flex-direction: column;
    /* border: solid 3px #dfdfdf; */
    /* margin-left: 20vw; */
    color:black;
    /* color: white; */
    opacity:70%;
    width: 75vw;
    height:-webkit-max-content;
    height:max-content;
    letter-spacing: 1px;
}

.flex_container h1{
    font-family: 'Playfair Display SC', serif;
    font-weight: 700;
    font-size: 7vw;
    /* padding: 30px 0px; */
    position: relative;
    left:0%;
    /* border: 1px solid black; */
    width:-webkit-min-content;
    width:min-content;
    line-height: 7vw;
    /* text-shadow: 1px 1px 4px white; */
    opacity: 1;
    text-align: left;
    margin: 0px;
}
.flex-p{
    letter-spacing: 3px;
    line-height: 2rem;
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 2rem;
}
.p-block{
    font-weight: 300;
    font-size: 2rem;
    letter-spacing: 3px;
    text-align: left;
    line-height: 2;
}

.nameContainer{
    /* border: 1px black solid; */
    width: 55%;
    /* margin-bottom: -3rem;*/ 
} 
.name{
    font-family: 'Playfair Display SC', serif;
    /* font-family: 'Poppins', sans-serif; */
    /* font-family: 'Open Sans', sans-serif; */
    font-weight: 800;
    font-size: 5vw;
    text-align: left;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.chatContainer{
    width: 48%;
    display: flex;
    justify-content: space-around;
    /* border: 1px solid black; */
    margin-bottom: 5%;
}
.chatName{
    font-family: 'DM Serif Display', serif;
    font-weight: 400;
    padding: 0;
    margin: 0;
    /* margin-top: -3%; */
}
.bottom-span{
    display: flex;
    width: 75%;
    /* margin-top: -3%; */
    justify-content: right;
}

.chat{
    color:black;
    opacity: 1;
    width: 40vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* border: 1px solid black; */
    top:30%
}
.idaText{
    /* border: 1px solid black; */
    font-family: 'Open Sans', sans-serif;
    width: 33vw;
    /* border: 1px solid black; */
}
.right-img{
    position: fixed;
    top: 0%;
    right: 0%;
    z-index: -2;
    width: 45vw;
    height: 100rem;
    opacity: 0; 
    background-repeat: no-repeat;
    background-size: cover;
    /* to be animated by framer motion */
}
.top-img{
    position: fixed;
    top: 32rem;
    right: 15vw;
    z-index: -1;
    /* tricks the browser to display equal size */
    width: 25%;
    height: 0;
    padding-bottom: 25%;

    opacity: 0; 
    background-image: url(/static/media/chatbot_cute_one_way.90348404.jpg);
    background-repeat: no-repeat;
    background-size: contain;
}
.chat-credit{
    opacity: 0;
    position: fixed;
    right: 15vw;
    top: 76vh;
}
/* .lines{
    width:20%;
    height: 1000px;
    border: 1px solid black;
    height: fit-content;

    display: flex;
    justify-content: space-around; 
    flex-direction: column;
}
.underline{
    display:block;
    width:50px;
    border-top: 5px solid orange;
} */
.button-container{
    position: relative;
    left: 0%;
    width:50%;
    height:10%;
    margin: 5% 0px;
    /* border: 1px solid black; */
}
.button{
    position: absolute;
    left: 0%;
    display: inline-block;
    cursor: pointer;

    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2.5px;
    font-size: 2rem;
    font-weight: 400;
    color: black;

    padding: 2.3% 2.5vw;
    border: 1px solid black;
    border-radius: 5px;

    opacity: 0.85;
    background-position:left bottom;
    background: linear-gradient(to right, white 50%, rgb(31, 30, 30) 50%);
    background-size: 200% 100%;
    transition:all 0.5s ease-in-out;
}
.button:hover {
    color: white;
    background-position:right bottom;
}
.landing-button{
    padding: 1.5% 2.5vw;
    border: 2px solid black;
    /* border-radius: 50px; */
    opacity: 1;
    border-radius: 5px;
    font-size: 2rem;
    font-weight: 400;
}
/* 
@keyframes text{
    0%{
        color: black;
        margin-bottom: -40px;
    }
    30%{
        letter-spacing: 5px;
    }
} */

.menu{
    font-family: 'Lato', sans-serif;
    position:fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 1.3%;
}
.menu .logo{
    position: relative;
    width:10%;
    color: black;
    font-weight: 400;
    font-size: 2.4rem;
    cursor: pointer;
}
.menu .right{
    position: relative;
    display: flex;
    justify-content: space-around;
    width:2.2vw;
    right:3vw;
    /* border: 1px solid black; */
    /* margin-top: 0.9%; */
}

.link{
    text-decoration: none;
    color: black;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    /* padding-bottom: 5px; */
}
/* -------- burger -------- */
.menu .burger{
    flex-basis: 0;
    flex-grow: 0;
    /* border:1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.menu .burger div{
    width: 2rem;
    height: 0.3rem;
    background-color: black;
    margin: 0.16rem;
}
/* .menu .link::after{
    content: '';
    display: block;
    width: 0px;
    height: 2px;
    background: black;
    transition: width 0.3s;
}
.menu .link:hover::after{
    width: 100%;
    transition: width ease-in-out;
} */
.page-number{
    position: fixed;
    display: block;
    width: 10vw;
    height: 15rem;
    left: 0%;
    bottom: 15rem;
    font-family: 'Montserrat', serif;
    font-weight: 700;
    font-size: 12rem;
    color: gray; 
}
.vl {
    /* opacity: 0.8; */
    border-left: 1px solid gray;
    position: fixed;
}
/* .left-line {
    margin-top: 12rem;
    left : 5vw;
    height: 80rem;
} */
.left-container{
    position: fixed;
    width: 3vw;
    height: 55vh;
    left: 3%;
    top: 25%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    color: black;
    /* border: 1px solid black; */
}
.section{
    font-size: 2rem;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    opacity: 0.5;

    display: block;
    width: 150%;
    height: 15%;
    padding: none;
    border: none;
    background: none;
    cursor: pointer;
    /* border: 1px solid black; */
}
.selected{
    /* border: 1px black solid; */
    font-size: 2.2rem;
    opacity: 1;
}
.right-container{
    position: fixed;
    width: 2vw;
    right : 3vw;
    margin-top: 13rem;
    height: 80rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    /* border: 1px solid black;  */
}
.right-line-top{
    position: absolute;
    height: 35%;
}
.right-line-bottom{
    position: absolute;
    height: 35%;
    top: 60%;
}
.progress-bar{
    position: absolute;
    /* right : 4vw; */
    top : 35%;
    width : 2vw;
    height : 25%;
    display:flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid black; */
}
.pair{
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.square{
    height: 1.25rem;
    width: 1.25rem;
    /* border: 1px solid white; */
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    /* margin: -2px,0px,0px,-10px; */
    /* position:absolute; */
    /* right: 4.5vw; */
    background-color: none;
    border: 2px solid black;
    z-index: 10;
}
.hidden{
    display:none;
}
.circle{
    position: absolute;
    height: 0.58rem;
    width: 0.58rem;
    border: 1px solid black;
    background-color: black;
    /* background-color: rgb(71, 71, 71); */
    opacity: 0.5;
    border-radius: 50%;
    /* background-color: white; */
}
.blur{
    top: 0%;
    left: 0%;
    position: fixed;
    background: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    height: 100vh;
    width: 100vw;
    z-index: 10;
}
.meetings-page{
    position: fixed;
    top: 10vh;
    left: 30vw;
    height: 75vh;
    width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    z-index: 11;
    /* border: 1px solid black; */
}
.meeting-title{
    width: 100%;
    /* border: 1px solid black; */
    text-align: center;
    font-size: 3rem;
    font-weight: 300;
}
.meeting-row{
    font-family: "Open Sans", sans-serif;
    display: flex;
    justify-content: space-around;
    width: 70%;
    margin-top: -5%;
    /* border: 1px solid black; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.meeting-div{
    background: none;
    position: relative;
    width: 40%;
    height: 20%;
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
}
.medium-length{
    width: 60%;
}
.medium-high-length{
    width: 75%;
}
.full-length{
    width: 100%;
}
.meeting-field {
    background: none;
}
.meeting-message{
    display: block;
    height: 7vh;
    width: 100%;
    /* border-bottom: none; */
    background: none;
    border: 1px solid black;
    font-size: 1.3vh;
    font-family: inherit;
    letter-spacing: 1px;
    line-height: 1.5vh;
    padding-left: 2.5%;
    padding-top: 2.5%;
}
.date-picker-div{
    width: 70%;
    margin-top: -5%;
}
.date-picker, .time-picker{
    width: 25%;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}
.captcha-div{
    margin-left: 10%;
    /* margin-top: 3%; */
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}
.button-row{
    width: 50%;
    display: flex;
    justify-content: space-around;
    margin-top: -5%;
}
.contact-button {
    background: none;
    border: 1px solid black;
    text-align: center;
    width: 40%;
    padding: 5px 8%;
    height: 5vh;
    font-size: 1.8vh;
    font-weight: 300;
    letter-spacing: 2px;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
}
.contact-button:hover{
    color: white;
    background-color: rgb(83, 83, 83);
    opacity: 0.9;
}
.contact{
    width: 100vw;
    height: 100vh;
    font-family: "Open Sans", sans-serif;
}
.divider{
    width: 84%;
    position: absolute;
    left: 8%;
}
.top-line{
    top: 11%;
}
.bottom-line{
    bottom:8%;
}
.contact-header{
    position: absolute;
    left: 8%;
    top: 11%;

    /* border: 1px solid black; */
    width: 10%;
}
.contact-title{
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 4.5vh;
    text-align: left;
    line-height: 5vh;
}

.contact-form{
    position: absolute;
    right: 10%;
    top: 15%;
    width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* border: 1px black solid; */

    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    flex-wrap: wrap;
}
.form-field{
    position: relative;
    width: 40%;
    height: 20%;
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
    /* border: 1px solid black; */
}

.form-field-label{
    width: 100%;
    /* border: 1px solid black; */
    font-size: 1.5vh;
    text-align: left;
    margin-bottom: 1vh;
}
.form-field-input{
    display: block;
    height: 3.5vh;
    font-size: 1.3vh;
    border: none;
    border-bottom: 1px solid black;
    padding-left: 2.5%;
    /* border-radius: 3.5vh; */
}
input.form-field-input:focus {
    outline: none;
    border-bottom: 2px solid black;
}
.form-message {
    height: 7vh;
    width: 40vw;
    border-bottom: none;
    border: 1px solid black;
    letter-spacing: 1px;
    line-height: 1.5vh;
    padding: 15px 20px;
}
textarea::-webkit-scrollbar {
    width: 0.3vw;
}

textarea::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    -webkit-box-shadow: none;
}

textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 0.15vw;
}
.required::after{
    content: "*";
    color: red;
}
.captcha-field{
    /* border: 1px solid black; */
    width: 90%;
    margin-top: 2vh;
    display: flex;
    /* justify-content: left; */
}
.captcha{
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    margin-left: -11%;
}
.submit-button-container{
    width: 90%;
    /* border: 1px solid black; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.submit-button{
    background: none;
    border: none;
    float: left;
    text-align: left;
    width: 10%;
    height: 5vh;
    line-height: 5vh;
    font-size: 1.8vh;
    font-weight: 300;
    font-weight: normal;
    letter-spacing: 2px;
    cursor: pointer;
}
.schedule-field{
    width: 90%;
    height: -webkit-min-content;
    height: min-content;
    cursor: pointer;
}
.schedule-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 3vh;
    border: 1px solid black;
    padding: 10px;
}
.schedule-button{
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 3px;
    font-weight: 400;

    width: 70%;
    color: black;
    padding: 10px 0px;
    /* border: 1px solid black; */
    /* border-radius: 30px; */
    font-size: 2.1rem;
}
.google-meet{
    width: 4vh;
    height: 4vh;
    padding-right: 5%;
    /* border: 4px solid black; */
}

.chat-container{
    position: absolute;
    top: 50%;
    left: 30%;
    width: 40%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* border: 1px solid black; */

}
.text-bar{
    border: 1px black solid;
    height: 3.5vh;
    border-radius: 3.5vh;
    padding: 5px;
}
.text{
    position: relative;
    background: none;
    border: none;
    outline:none;
    color: black;
    opacity: 80%;
    float: left;
    padding: 0;
    padding-left: 3%;
    width: 85%;
    font-size: 1.8vh;
    line-height: 3.5vh;
}
::-webkit-input-placeholder{
    color: black;
    opacity: 30%;
    font-family: Lato, sans-serif;
}
:-ms-input-placeholder{
    color: black;
    opacity: 30%;
    font-family: Lato, sans-serif;
}
::placeholder{
    color: black;
    opacity: 30%;
    font-family: Lato, sans-serif;
}
.send-btn{
    color: black;
    float: right;
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    font-size: 2vh;
    border-left: none;
    cursor: pointer;
}
.arrow{
    display: block;
    position: fixed;
    top: 7%;
    left: 5%;
    text-decoration: none;
    border: none;
    background: none;
    cursor: pointer;
    /* border: 1px solid black; */
    font-size: 2vw;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
}
.response-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    /* border: 1px solid black; */
}
.line_art{
    display: block;
    width: 25vw;
    /* opacity: 70%; */
}
.message{
    font-size: 1.5vw;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}
.message-filler{
    font-size: 3vw;
}
/* Buttons */
.button-bar{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    margin-top: 5vh;
    /* left: -30%; */
    /* border: 1px solid black; */
}
.rasabutton{
    position: relative;
    /* left: 0%; */
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    background: none;

    font-family: 'Roboto', sans-serif;
    letter-spacing: 4px;
    font-size: 25px;
    line-height: 5vh;
    padding: auto;
    font-weight: 400;
    color: black;

    width: 25%;
    height: 5vh;
    /* padding: 2% 2.5vw; */
    border: 1px solid black;
    border-radius: 50px;
    z-index: 3;
}
.uninterested{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3vh;
    padding: 0px 5%;
}

/* <--------------- Wave Stuff ---------------> */
@-webkit-keyframes move_wave {
    0% {
        -webkit-transform: translateX(0) translateZ(0) scaleY(1);
                transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
                transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
                transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}
@keyframes move_wave {
    0% {
        -webkit-transform: translateX(0) translateZ(0) scaleY(1);
                transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
                transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
                transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}
.waveWrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5%;
    top: 60%;
    margin: auto;
}
.waveWrapperInner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    bottom: -1px;
    /* background-image: linear-gradient(to top, #86377b 20%, #27273c 80%); */
}
.bgTop {
    z-index: -5;
    opacity: 0.5;
}
.bgMiddle {
    z-index: -10;
    opacity: 0.75;
}
.bgBottom {
    z-index: -15;
}
.wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
}
.waveTop {
    background-size: 50% 100px;
    background-image: url(/static/media/wave-top.5fe38509.png);
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
   -webkit-animation: move-wave 3s;
   -webkit-animation-delay: 1s;
   animation-delay: 1s;
}
.waveMiddle {
    background-size: 50% 120px;
    background-image: url(/static/media/wave-mid.f1866e4b.png);
}
.waveAnimation .waveMiddle {
    -webkit-animation: move_wave 10s linear infinite;
            animation: move_wave 10s linear infinite;
}
.waveBottom {
    background-size: 50% 100px;
    background-image: url(/static/media/wave-bot.9e6b49b5.png);
}
.waveAnimation .waveBottom {
    -webkit-animation: move_wave 15s linear infinite;
            animation: move_wave 15s linear infinite;
}
.about{
    color:black;
    opacity: 1;
    width: 40vw;
    top: 18vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.aboutP{
    /* border: 1px solid black; */
    font-family: 'Open Sans', sans-serif;
    width: 30vw;
    /* border: 1px solid black; */
}
.aboutContainer{

}
.aboutName{
    margin-bottom: 8%;
    font-family: 'DM Serif Display', serif;
    font-weight: 400;
}
.about-image{
    position: fixed;
    border-radius: 50%;
    border: 1px solid gray;
    top: 30%;
    right: 15%;
    /* z-index: -2; */
    opacity: 0;
    width: 22%;
    height: 0;
    padding-bottom: 22%;
    background-image: url(/static/media/about_img.ac3023bf.jpg);
    background-repeat: no-repeat;
    background-position: center;
    /* background-size: contain; */
    background-size: 100%;
}
.about-button{
    letter-spacing: 1px;
    padding-left: 8%;
    padding-right: 7%;
}
.about-container{
    width: 60%;
}
.next-button-about{
    left: 50%;
    letter-spacing: 1.5px;
}

.next-button{
    left: 45%;
    width: -webkit-max-content;
    width: max-content;
    padding-left: 5%;
    padding-right: 5%;
}
.work-button{
    letter-spacing: 0.5px;
    padding-left: 7%;
    padding-right: 7%;
}
.work-button-container{
    width:70%;
}
.experienceP{
    width: 45vw;
}
.experience-image{
    right: 10%;
}
.experience{
    top: 17%;
}
